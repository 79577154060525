angular = require("angular");
var SF = require("skeinetFunctions");
var config = require("config");

angular.module("oxset").factory('recordService', ['$http', 'localStorageService', function ($http, localStorageService) {

  function apiPostToken(path, obj, onSuccess, onError) {
    //var api_token = localStorageService.get('api_token');
    $http.post(config.apiUrlToken + path, obj).
      then(function (response) {
        localStorageService.set("api_token", response.data.access_token);
        onSuccess(response);
      }, function (response) {
        onError(response);
      });
  }

  function apiPost(path, obj, onSuccess, onError) {
    var api_token = localStorageService.get('api_token');
    $http.post(config.apiUrl + path, obj, { headers: { "Authorization": 'Bearer ' + api_token } }).
      then(function (response) {
        onSuccess(response);
      }, function (response) {
        onError(response);
      });
  }

  function apiGet(path, onSuccess, onError) {
    var api_token = localStorageService.get('api_token');
    $http.get(config.apiUrl + path, { headers: { "Authorization": 'Bearer ' + api_token } }).
      then(function (response) {
        onSuccess(response);
      }, function (response) {
        onError(response);
      });
  }


  return {
    apiPostToken: apiPostToken,
    apiPost: apiPost,
    apiGet: apiGet,
  }

}]);