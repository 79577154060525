var angular = require("angular");
var angularLocalStorage = require("angular-local-storage");
var SF = require("skeinetFunctions");
var config = require("config");

var url = window.location.href;
var defaultOffice='';
if(url.indexOf("?")>-1){
    url = url.split("?");
    url = url[1].split("=");
    defaultOffice = url[1];
    if(!defaultOffice){defaultOffice='';}
    defaultOffice = defaultOffice.charAt(0).toUpperCase() + defaultOffice.slice(1);
}


window.Popper = require("popper.js").default;

try {
  window.$ = window.jQuery = require("jquery");
  require("bootstrap");
} catch (e) { }

var placementApp = angular.module("oxset", ["LocalStorageModule"]);

placementApp.controller("mainController", [
  "$scope",
  "$window",
  "$http",
  "$location",
  "recordService",
  "localStorageService",
  function ($scope, $window, $http,$location, recordService, localStorageService, $interval ) {
    $scope.SYSTEM = {};
    $scope.SYSTEM.status = "waiting password";
    $scope.answers = [];
    $scope.examNotAvailable = false;
    $scope.loginError = false;
    $scope.loginOk = false;
    $scope.loginWaiting = false;
    $scope.examsScreen = 2;
    $scope.deliveringExam = false;
    $scope.examAlreadyCompleted = false;
    $scope.login = {};
    $scope.account = {};
    $scope.badAccount = {};
    $scope.nextPartStatus = 0;
    $scope.actualPart = 1;
    $scope.forceOffice = false;
    $scope.forceOfficeLabel = '';

    $scope.defaultOffice = defaultOffice;

    $scope.timer = {};
    $scope.timer.active = false;
    $scope.timer.tranche1 = 0;
    $scope.timer.tranche2 = 0;
    $scope.timer.value = 0;

    $scope.office = {};

    // Prova modifica file

    $scope.mode = 'basic'
    var href =$window.location.href
    v = href.split("?")
    if(v.length>1)
    {
        w = v[1].split("=")
        if(w.length>1)
        {
            $scope.mode = w[0] == 'mode' && w[1] == 'placement_event' ? 'new_client' : 'basic'
        }
    }
    

    $scope.getFirstPartOfSecondTranche = function(){
        if(!$scope.Exam){ return 5; }
        if(!$scope.Exam.tranches){ return 5; }
        var trance = $scope.Exam.tranches.split("/");
        return parseInt(trance[1]);
    }

    function timerTick() {
        if($scope.timer.active){
            if($scope.timer.tranche1>0 && $scope.actualTranche()==1){
                $scope.timer.tranche1--;
                $scope.timer.value = $scope.timer.tranche1;
                if($scope.timer.tranche1 == 0){
                    if($scope.overBarrageScore()){
                        $scope.actualPart = $scope.getFirstPartOfSecondTranche();
                    } else {
                        $scope.timer.active = false;
                        $scope.deliverExam("timeout");
                    }
                }
            } else if($scope.timer.tranche2>0 && $scope.actualTranche()==2){
                $scope.timer.tranche2--;
                $scope.timer.value = $scope.timer.tranche2;
                if($scope.timer.tranche2 == 0){
                    $scope.timer.active = false;
                    $scope.deliverExam("timeout");
                }
            } else {

            }
            $scope.$apply();
        }         
    }
    var clock = setInterval(timerTick, 1000);

    $scope.actualTranche = function(){
        if(!$scope.Exam){ return 1; }
        if(!$scope.Exam.tranches){ return 1; }
        var trance = $scope.Exam.tranches.split("/");
        trance = parseInt(trance[0]);
        return $scope.actualPart<=trance?1:2;
    }

    $scope.showPartCloseButton = function(){
        if(!$scope.Exam){
            return false;
        }
        if(!$scope.Exam.is_sequential){
            return false;
        }
        var b = $scope.nextPartStatus==0;
        b = b && $scope.actualPart<$scope.Placement.length;

        if($scope.Exam.tranches){
            var trance = $scope.Exam.tranches.split("/");
            trance = parseInt(trance[0]);
            b = b && !($scope.actualPart==trance && !$scope.overBarrageScore());
        }        
        
        return b;
    }

    $scope.overBarrageScore = function(){
        if(!$scope.Exam){
            return true;
        }
        if(!$scope.Exam.tranches){
            return true;
        }
        var trance = $scope.Exam.tranches.split("/");
        trance = parseInt(trance[0]);
        var correctAnswersCount = 0;
        for(var i=0; i<trance; i++){
            var part = $scope.Placement[i];
            for(var j=0; j<part.blocks[1].data.sentences.length; j++){
                question = part.blocks[1].data.sentences[j];
                if(question.answer == question.studentAnswer){
                    correctAnswersCount++;
                }
            }
        }
        return (trance==5 && correctAnswersCount>=26) || (trance==6 && correctAnswersCount>=32) || (trance==7 && correctAnswersCount>=37);
    }

    $scope.showPartTab = function(part){

        part = parseInt(part);
        
        if(!$scope.Exam){
            return true;
        }
        if(!$scope.Exam.tranches){
            return true;
        }
        if($scope.Exam.tranches==''){
            return true;
        }
        if($scope.Exam.tranches.indexOf("/")==-1){
            return true;
        }
        var trance = $scope.Exam.tranches.split("/");
        trance = parseInt(trance[0]);
        if(!trance){
            return true;
        }
        return part<=trance || part>trance && $scope.overBarrageScore();
    }   
    $scope.showPartTab();
    
    $scope.closePartAndGotToNext = function(){
        if(parseInt($scope.actualPart)<=9){
            $scope.Placement[$scope.actualPart-1].enable_status = 2;
            $scope.actualPart++;
            $scope.Placement[$scope.actualPart-1].enable_status = 0;
            $('#part-'+$scope.actualPart+"tab").click();
            $scope.nextPartStatus = 0;
        }
    }

    $scope.resetAccount = function () {
      $scope.account = {};
      $scope.account.first_name = "";
      $scope.account.last_name = "";
      $scope.account.email = "";
      $scope.account.email_confirmation = "";
      $scope.account.phone = "";
      $scope.account.office_id = 0;
      $scope.account.privacy = false;
      console.log("$scope.account", $scope.account);
    }
    $scope.resetAccount();

    $scope.systemReset = function () {
      $scope.SYSTEM = {};
      $scope.SYSTEM.status = "waiting password";
      $scope.answers = [];
      $scope.examNotAvailable = false;
      $scope.loginError = false;
      $scope.examsScreen = 2;
      $scope.Placement = {};
      $scope.deliveringExam = false;
      $scope.login = {};
      $scope.login.id = 0;
      $scope.login.email = "";
      $scope.login.password = "";
      $scope.login.where_met = "";
      $scope.login.privacy = false;
      //$scope.login.id = 171;
      //$scope.login.email = "hello@enriconardo.com";
      //$scope.login.password = "TSCn6RSK";
      $scope.examAlreadyCompleted = false;
      $scope.timer = {};
      $scope.timer.active = false;
      $scope.timer.tranche1 = 0;
      $scope.timer.tranche2 = 0;
      $scope.timer.value = 0;
    };
    $scope.systemReset();

    function getOffices() {
      recordService.apiGet("offices",
        function (response) {
          $scope.offices = response.data;
          for(var i=0; i<$scope.offices.length; i++){
              if($scope.offices[i].name.split(" ").join("").toLowerCase() == $scope.defaultOffice.split(" ").join("").toLowerCase()){
                if($scope.offices[i].name != 'Casale di Scodosia')
                {
                  $scope.account.office_id = $scope.offices[i].id;
                  $scope.forceOffice = true;
                  $scope.forceOfficeLabel = $scope.offices[i].name; 
                }
                break;
              }
          }
        },
        function (response) {
        }
      );
    }
    getOffices();

    function getApiToken() {
      var data = {};
      data.client_id = config.client_id;
      data.client_secret = config.client_secret;
      data.grant_type = config.grant_type;
      data.scope = config.scope;
      // SF.CL("getApiToken SEND", data);
      recordService.apiPostToken(
        "oauth/token",
        data,
        function (response) {
        },
        function (response) {
          SF.CL("getApiToken ERROR", response);
        }
      );
    }
    getApiToken();

    $scope.selectNavPill = function (part, source) {
      var buff = "";
      if (source == "up") {
        buff = "-down";
      }

      for (i = 0; i <= 10; i++) {
        $("#part-" + i + "-tab" + buff).removeClass("active");
      }
      $("#part-" + part.part + "-tab" + buff).addClass("active");

      if (buff == "-down") {
        buff = "";
      } else {
        buff = "-down";
      }
      $("#part" + part.part + "-tab" + buff + '"]').tab("show");
    };

    $scope.examStart = function () {
      recordService.apiPost(
        "placement-test/" + $scope.ExamHeader.id + "/student/" + $scope.Student.id + "/start",
        {},
        function (response) {
          //SF.CL("examStart OK", response);
          $scope.Exam = response.data;
          $scope.timer.active = false;
          if($scope.Exam.has_timer){
            $scope.timer.active = true;
            $scope.timer.tranche1 = parseInt($scope.Exam.timer[0]);
            $scope.timer.tranche2 = parseInt($scope.Exam.timer[1]);
          }

          $scope.Placement = $scope.Exam.questions;

          angular.forEach($scope.Placement, function (part, partKey) {
            $scope.Placement[partKey].enable_status = 0;
            if($scope.Exam.is_sequential){
                $scope.Placement[partKey].enable_status = 1;
            }
            angular.forEach(part.blocks[1].data.sentences, function (sentence, sentenceKey) {
              $scope.Placement[partKey].blocks[1].data.sentences[sentenceKey].studentAnswer = "";
            });
          });

          $scope.Placement[0].enable_status = 0;

          $scope.SYSTEM.status = "exam";
        },
        function (response) {
          SF.CL("examStart ERROR", response);
        }
      );
    };

    $scope.today = function () {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      return dd + "/" + mm + "/" + yyyy;
    };

    $scope.deliverExam = function (reason) {
      if(reason==undefined){reason ="closed by user";}
      $scope.deliveringExam = true;
      //SF.CL($scope.answers);
      var data = {};
      data.answers = $scope.answers;
      recordService.apiPost(
        "placement-test/" + $scope.ExamHeader.id + "/student/" + $scope.Student.id + "/end",
        data,
        function (response) {
          if($scope.office.redirect_url !== null && $scope.office.redirect_url !== undefined && $scope.office.redirect_url !== ''){
            $window.location.href = $scope.office.redirect_url;
          } else {
            $scope.SYSTEM.status = "examConfirmed";
            $scope.SYSTEM.examClosedReason = reason;
          }
          //$scope.systemReset();
        },
        function (response) {
          SF.CL("deliverExam ERROR", response);
        }
      );
    };

    $scope.listenExam = function (target) {
      if (target == undefined) {
        target = 1;
      }
      var sentencesDone = 0;
      var sentencesTotal = 0;
      $scope.answers = [];
      angular.forEach($scope.Placement, function (part, partKey) {
        var questionAnswers = [];

        angular.forEach(part.blocks[1].data.sentences, function (sentence, sentenceKey) {
            questionAnswers.push(sentence.studentAnswer);
            if($scope.overBarrageScore()){
                sentencesTotal += 1;
            } else {
                 var trance = $scope.Exam.tranches.split("/");
                trance = parseInt(trance[0]);
                if(parseInt(part.part)<=trance){
                    sentencesTotal += 1;
                }
            }
            if (sentence.studentAnswer != "") {
                sentencesDone += 1;
            }
        });

        var question = {};
        question.question_id = part.id;
        question.answers = questionAnswers;
        $scope.answers.push(question);
      });

      var s = "";
      switch (target) {
        case 1:
          s = "Esame in corso - Frasi " + sentencesDone + " di " + sentencesTotal + " completate";
          if (sentencesDone == sentencesTotal && sentencesTotal != 0) {
            s = "Hai completato tutte le frasi, ora puoi confermare questo esame";
          }
          break;
        case 2:
          if (sentencesDone == sentencesTotal && sentencesTotal != 0) {
            s = "Hai completato tutte le frasi, ora puoi confermare questo esame";
          }
          if (sentencesDone != sentencesTotal && sentencesTotal != 0) {
            s = "Attenzione Esame incompleto! Hai completato " + sentencesDone + " frasi su " + sentencesTotal + "; una volta consegnato l'esame non puoi più tornare indietro";
          }
          if (sentencesDone == 0) {
            s = "Attenzione! Non hai completato nemmeno una domanda. Stai consegnato questo esame in bianco. Sei sicuro?";
          }
          break;
      }

      return s;
    };

   

    $scope.badLogin = {};
    $scope.badLoginEmpty = function () {
      $scope.badLogin.exam = {};
      $scope.badLogin.student = {};
      $scope.badLogin.student.email = false;
      $scope.badLogin.exam.id = false;
      $scope.badLogin.exam.password = false;
    };
    $scope.badLoginEmpty();

    $scope.badAccountEmpty = function () {
      $scope.badAccount = {};
      $scope.badAccount.email = false;
      $scope.badAccount.email_confirmation = false;
      $scope.badAccount.first_name = false;
      $scope.badAccount.last_name = false;
      $scope.badAccount.privacy = false;
      $scope.badAccount.phone = false;
    };
    $scope.badAccountEmpty();



    $scope.sendCredentials = function () {
      $scope.badLoginEmpty();
      var data = {};
      data.mode = $scope.mode;
      data.student = {};
      data.exam = {};
      data.student.email = $scope.login.email;
      data.student.email_confirmation = $scope.login.email_confirmation;
      data.student.first_name = $scope.login.first_name;
      data.student.last_name = $scope.login.last_name;
      data.student.where_met = $scope.login.where_met;
      data.exam.id = $scope.login.id;
      data.exam.password = $scope.login.password;

      if ($scope.login.privacy == false) {
        $scope.badLogin.privacy = true;
        return;
      }

      //SF.CL("sendCredentials - SEND", data);
      $scope.loginError = false;
      recordService.apiPost(
        "placement-test/can-i-take",
        data,
        function (response) {
          //SF.CL("sendCredentials - RETURN", response);
          switch (response.status) {
            case 200:
            case 201:
              if (response.data.exam.status == "draft") {
                $scope.examNotAvailable = true;
              } else {
                $scope.SYSTEM.status = "start exam";
                $scope.ExamHeader = response.data.exam;
                $scope.Student = response.data.student;
                $scope.office = response.data.office;
              }
              break;
            default:
              $scope.loginError = true;
              break;
          }
        },
        function (response) {
         // SF.CL('response', response)
          var b = true;
          angular.forEach(response.data, function (value, key) {
            if (key.toString().indexOf(".") > -1) {
              var v = key.split(".");
              $scope.badLogin[v[0]][v[1]] = true;
              b = false;
            }
          });
          switch (response.status) {
            case 500:
              $scope.loginError = true;
              break;
            case 403:
              $scope.examNotAvailable = true;
              break;
            case 409:
              $scope.examAlreadyCompleted = true;
              break;
          }
          SF.CL("sendCredentials - ERROR", response);
        }
      );
    };

    $scope.registerAccount = function () {
      $scope.badAccountEmpty();
      if ($scope.account.privacy == false) {
        $scope.badAccount.privacy = true;
        return;
      }
      if($scope.account.phone == ''){
        $scope.badAccount.phone = true;
        return;
      } 
      var data = {};
      data = $scope.account;
      $scope.loginWaiting = true;
      $scope.loginError = false;
      recordService.apiPost(
        "placement-test/personal/new",
        data,
        function (response) {
          $scope.loginWaiting = false;
          //SF.CL("registerAccount - RETURN", response);
          switch (response.status) {
            case 200:
            case 201:
              $scope.loginOk = true;
              break;
            default:
              $scope.loginWaiting = false;
              var b = true;
              angular.forEach(response.data, function (value, key) {
                $scope.badAccount[key] = true;
                b = false;
              });
              break;
          }
        },
        function (response) {
          $scope.loginWaiting = false;
          var b = true;
          console.log("response", response);
          angular.forEach(response.data, function (value, key) {
            console.log("key", key);
            $scope.badAccount[key] = true;
            b = false;
          });
          SF.CL("registerAccount - ERROR", response);
        }
      );
    };
  }
]);

placementApp.filter('secondsToDateTime', [function() {
    /**
     * This code returns a date string formatted manually.
     * Code "new Date(1970, 0, 1).setSeconds(seconds)" returns malformed output on days.
     * Eg. 4 days, magically becomes 5, 15 becomes 16 and so on...;
     * */
    return function(seconds) {
    var days = Math.floor(seconds/86400);
    var hours = Math.floor((seconds % 86400) / 3600);
    var mins = Math.floor(((seconds % 86400) % 3600) / 60);
    var secs = ((seconds % 86400) % 3600) % 60;
    return  ('00'+mins).slice(-2)+':' + ('00'+secs).slice(-2);
    };
}]);

require("recordService");
