/*module.exports = Object.freeze({
    client_id: '6',
    client_secret: '7qc3PsAYHE1MJolp30egPy7dkbaSXUiC4yrTtqoi',
    grant_type: 'client_credentials',
    scope: '',
    apiUrlToken: 'https://staging-api.oxfordschool.com',
    apiUrl: 'https://staging-api.oxfordschool.com/api/'
});*/


module.exports = Object.freeze({
    client_id: '6',
    client_secret: 'YWjWhn3RZ5GKEB1UyCA1ICJcjwdRWJ9PypRCZapZ',
    grant_type: 'client_credentials',
    scope: '',
    apiUrlToken: 'https://api.oxfordschool.com/',
    apiUrl: 'https://api.oxfordschool.com/api/'
});
