module.exports = {

  CL: function(a, b){
    if(a == undefined){a = '';}
    if(b == undefined){
      console.log(a);    
    } else {
      console.log(a,b);
    }

  }



};